<template>
  <div class="page-content">
    <v-overlay :value="newProjectState" class="overlay-app"></v-overlay>
    <v-overlay
      :value="GestionAppels"
      class="overlay-app-Gestion-appel"
    ></v-overlay>
    <!-- ----------------------------------  ACTION BTN  PROJET  (ADD / EXPORT /IMPORT ..) -------------------------------------------------  -->

    <div class="add-projet-filter">
      <div
        class="projects-loader-show-statut"
        v-if="(getEtapesLoading || initLoading) && !selectedModalFileComputed"
      >
        <LoadingComponent />
      </div>
      <div
        class="etapes-leads"
        v-else-if="!selectedModalFileComputed && !initLoading"
      >
        <v-menu
          ref="menuEtapeProjet"
          offset-y
          transition="scale-transition"
          :close-on-content-click="false"
          max-width="416px !important"
          min-width="416px !important"
          :nudge-bottom="8"
          :nudge-left="10"
        >
          <template v-slot:activator="{ attrs, on }">
            <div v-bind="attrs" v-on="on">
              <span class="mr-2 img-vector">
                <img src="@/assets/img/vector.png" alt="" />
              </span>
              <span class="font-sz-12 font-text bold-700 mr-3 text-capitalize"
                >{{
                  selectedEtape && selectedEtape.index
                    ? selectedEtape.index
                    : '1'
                }}-
                {{
                  selectedEtape && selectedEtape.name
                    ? selectedEtape.name
                    : selectedEtape
                }}</span
              >
              <v-progress-circular
                v-if="getEtapesLoading"
                indeterminate
                color="#5C2DD3"
                class="sz-loader-icon"
              ></v-progress-circular>
              <v-icon class="icon-flech-etapes cursor-pointer ml-3" v-else
                >mdi-chevron-down</v-icon
              >
            </div>
          </template>
          <v-list class="list-item-pipeline-global-scroll padding-etape" dense>
            <div
              v-if="getEtapesLoading || initLoading"
              class="color-crm font-sz-12 font-text bold-700"
            >
              {{ $t('loading') }}
            </div>
            <div v-else>
              <div v-if="getEtapes && getEtapes.length">
                <v-list-item
                  v-for="etape in getEtapes"
                  :key="'current-pipeline-stage-' + etape.id"
                  dense
                  link
                  @click="changeEtapes(etape, false)"
                  class="list-item-etape"
                  :class="{
                    active: selectedEtape && etape.id == selectedEtape.id
                  }"
                >
                  <v-list-item-title class="block-flex-align-space-between">
                    <div class="mr-2 title-etape">
                      <span
                        class="font-sz-13 font-text bold-600 lettre-space-04 text-capitalize"
                        >{{ etape.index }}- {{ etape.name }}</span
                      >
                    </div>
                    <div class="block-right">
                      <span class="count">
                        {{
                          etape && etape.projets_count
                            ? etape.projets_count
                            : '0'
                        }}
                      </span>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </div>
              <div v-else class="div-aucun step">
                <div class="titre">
                  {{ $i18n.locale === 'fr' ? 'Aucune etape' : 'No step' }}
                </div>
              </div>
            </div>
          </v-list>
        </v-menu>
      </div>
      <div class="etapes-leads" v-if="selectedModalFileComputed">
        <v-menu
          ref="menuEtapeFileProjet"
          offset-y
          transition="scale-transition"
          :close-on-content-click="false"
          max-width="416px !important"
          min-width="416px !important"
          :nudge-bottom="8"
          :nudge-left="10"
          @input="refreshList"
        >
          <template v-slot:activator="{ attrs, on }">
            <div v-bind="attrs" v-on="on">
              <span class="mr-2 img-vector">
                <img src="@/assets/img/vector.png" alt="" />
              </span>
              <span class="font-sz-12 font-text bold-700 mr-3 text-capitalize"
                >{{
                  selectedEtape && selectedEtape.index
                    ? selectedEtape.index
                    : '1'
                }}-
                {{
                  selectedEtape && selectedEtape.name
                    ? selectedEtape.name
                    : selectedEtape
                }}</span
              >
              <v-progress-circular
                v-if="getEtapesLoading"
                indeterminate
                color="#5C2DD3"
                class="sz-loader-icon"
              ></v-progress-circular>
              <v-icon class="icon-flech-etapes cursor-pointer ml-3" v-else
                >mdi-chevron-down</v-icon
              >
            </div>
          </template>
          <v-list class="list-item-pipeline-global-scroll" dense>
            <div
              v-if="getEtapesLoading || initLoading"
              class="color-crm font-sz-12 font-text bold-700 mt-2 mb-2 mt-2 "
            >
              {{ $t('loading') }}
            </div>
            <div v-else>
              <div v-if="getEtapes && getEtapes.length">
                <v-list-item-group
                  v-for="etape in getEtapes"
                  :key="'current-pipeline-stage-' + etape.id"
                  dense
                  link
                  class="list-groupe"
                  :class="{
                    active: selectedEtape && etape.id == selectedEtape.id
                  }"
                >
                  <v-list-item-content
                    class="padding-statut list-type-onglet-projet border-none cursor-pointer"
                    @click.prevent.stop="rotateFlechStep(etape)"
                  >
                    <v-list-item-title
                      class="block-flex-align-space-between"
                      :class="{
                        'mb-3': etape.flech,
                        'border-step': etape.flech
                      }"
                    >
                      <div class="mr-2 title-etape">
                        <span
                          class="font-sz-12 font-text bold-700 lettre-space-04  text-capitalize ml-2"
                          :class="{
                            'color-crm': etape.flech,
                            'color-black': !etape.flech
                          }"
                          >{{ etape.index }}- {{ etape.name }}</span
                        >
                      </div>
                      <div class="block-right-step">
                        <span class=" count-step mt-1">
                          {{
                            etape && etape.projets_count
                              ? etape.projets_count
                              : '0'
                          }}
                        </span>
                      </div>
                      <div class="flesh-right-step mr-2">
                        <v-icon
                          class="icon-flech-etapes cursor-pointer img-h-22"
                          :class="{
                            'color-crm': etape.flech,
                            'color-black': !etape.flech
                          }"
                          v-if="etape.flech"
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          class="icon-flech-etapes cursor-pointer img-h-22"
                          :class="{
                            'color-crm': etape.flech,
                            'color-black': !etape.flech
                          }"
                          v-else
                          >mdi-chevron-down</v-icon
                        >
                      </div>
                    </v-list-item-title>
                    <div v-if="etape.flech">
                      <div
                        v-if="proccessingPipelineFilter || initLoading"
                        class="color-crm font-sz-12 font-text bold-700 text-center"
                      >
                        {{ $t('loading') }}
                      </div>
                      <div v-else>
                        <div v-if="etape && etape.stages.length">
                          <v-list-item-group
                            class="list-groupe"
                            v-for="stage in etape.stages"
                            :key="'current-pipeline-stage-' + stage.id"
                            dense
                            link
                          >
                            <v-list-item-content
                              class="padding-category list-type-onglet-projet"
                              :class="{
                                'hover-none': !stage.flech,
                                active: stage.flech
                              }"
                              @click.prevent.stop="rotateFlechPipeline(stage)"
                            >
                              <v-list-item-title
                                class="block-flex-align-space-between"
                                :class="{
                                  'mb-3': stage.flech,
                                  'border-step-category': stage.flech
                                }"
                              >
                                <div class="text-center mr-2 text-ellipse">
                                  <span
                                    class="font-sz-12 font-text bold-700 color-black lettre-space-04 "
                                  >
                                    {{
                                      selectedEtape && selectedEtape.index
                                        ? selectedEtape.index
                                        : '1'
                                    }}.{{ stage.order + 1 }}
                                  </span>
                                  <span
                                    :title="stage.name"
                                    class="font-sz-12 font-text bold-700 lettre-space-04 ml-2 color-black text-capitalize"
                                    >{{ stage.name }}</span
                                  >
                                </div>

                                <div class="block-right-etapes pipeline">
                                  <div
                                    class="block-flex-align-space-between width-100"
                                  >
                                    <span
                                      class="count-color-crm"
                                      :title="stage.projets_count"
                                    >
                                      {{
                                        stage && stage.projets_count
                                          ? stage.projets_count
                                          : '0'
                                      }}
                                    </span>
                                    <div class="flesh-right">
                                      <v-icon
                                        class="icon-flech-etapes color-black cursor-pointer"
                                        v-if="stage.flech"
                                        >mdi-chevron-up</v-icon
                                      >
                                      <v-icon
                                        class="icon-flech-etapes color-black cursor-pointer"
                                        v-else
                                        >mdi-chevron-down</v-icon
                                      >
                                    </div>
                                  </div>
                                </div>
                              </v-list-item-title>
                              <template v-if="stage.flech">
                                <div
                                  v-if="
                                    getEtapesSubstageLoading &&
                                      (!stage.subStages ||
                                        !stage.subStages.length)
                                  "
                                  class="color-crm font-sz-12 font-text bold-700 text-center"
                                >
                                  {{ $t('loading') }}
                                </div>
                                <v-list-item
                                  class="list-item"
                                  v-for="subStages in stage.subStages"
                                  :key="
                                    'current-pipeline-subStages-' + subStages.id
                                  "
                                  link
                                  @click.prevent.stop="
                                    setNewStageWithSub(stage, subStages)
                                  "
                                  :class="{
                                    active:
                                      checkedProjectsOne &&
                                      checkedProjectsOne.subStage &&
                                      checkedProjectsOne.sub_stage_id ==
                                        subStages.id
                                  }"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="list-type-onglet-projet block-flex-align-space-between"
                                    >
                                      <div
                                        class="mr-2 font-text text-ellipse-substage"
                                      >
                                        <span
                                          class="font-sz-12 font-text color-black bold-500 lettre-space-04"
                                        >
                                          {{
                                            selectedEtape && selectedEtape.index
                                              ? selectedEtape.index
                                              : '1'
                                          }}.{{ stage.order + 1 }}.{{
                                            subStages.order + 1
                                          }}</span
                                        >
                                        <span
                                          class="font-sz-12 lettre-space-04 color-black bold-500 font-text ml-2"
                                          :title="subStages.name"
                                        >
                                          {{ subStages.name }}</span
                                        >
                                      </div>
                                      <div class="block-right-ss pipeline">
                                        <span
                                          class="count-ss stage color-ss"
                                          :title="subStages.projets_count"
                                        >
                                          {{
                                            subStages && subStages.projets_count
                                              ? subStages.projets_count
                                              : '0'
                                          }}
                                        </span>
                                      </div>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <div
                                  v-if="
                                    !getEtapesSubstageLoading &&
                                      (!stage.subStages ||
                                        !stage.subStages.length)
                                  "
                                  class="div-aucun"
                                >
                                  <div class="titre">
                                    {{
                                      $i18n.locale === 'fr'
                                        ? 'Aucun stade'
                                        : 'No stage'
                                    }}
                                  </div>
                                </div>
                              </template>
                            </v-list-item-content>
                          </v-list-item-group>
                        </div>
                        <div v-else class="div-aucun step">
                          <div class="titre">
                            {{
                              $i18n.locale === 'fr'
                                ? 'Aucune catégorie de stade'
                                : 'No category stage'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item-group>
              </div>
              <div v-else class="div-aucun step">
                <div class="titre">
                  {{ $i18n.locale === 'fr' ? 'Aucune etape' : 'No step' }}
                </div>
              </div>
            </div>
          </v-list>
        </v-menu>
      </div>
      <div class="action-btn-projet">
        <!------------------------------Button Export Excel  ---------------------------->
        <v-btn
          class="btn-filter mr-2"
          color="#45148F"
          dark
          small
          title="Export Excel"
          v-if="visibilityExportExcel && getTableBoard && getTableBoard.length"
          @click="exportExcel"
        >
          <v-progress-circular
            v-if="getProjectsExportProcessing"
            indeterminate
            color="#fff"
            class="sz-loader-icon"
          ></v-progress-circular>
          <v-icon class="sz-icon-filter" v-else>mdi-tray-arrow-down</v-icon>
        </v-btn>

        <!------------------------------Button Import Excel  ---------------------------->

        <v-btn
          :class="{ hidden: selectedModalFileComputed }"
          class="btn-filter mr-2"
          color="#45148F"
          dark
          small
          title="Import Excel"
          @click="importExcel"
          v-if="currentType && visibilityImportExcel"
        >
          <input type="file" ref="file" :style="{ display: 'none' }" />
          <v-progress-circular
            v-if="getProjectsUploadProcessing"
            indeterminate
            color="#fff"
            class="sz-loader-icon"
          ></v-progress-circular>
          <v-icon class="sz-icon-filter" v-else>mdi-import</v-icon>
        </v-btn>
        <input
          type="file"
          style="display: none"
          ref="fileInput"
          accept=".xlsx"
          @input="handleFileUpload"
        />

        <!------------------------------Button Filter  ---------------------------->

        <!-- <v-btn
          class="btn-add-projet mr-3"
          color="#45148F"
          dark
          small
          @click="createNewProject"
          :disabled="readonly"
        >
          <v-icon class="sz-icon-plus mr-2">mdi-plus</v-icon>
          {{ $i18n.locale === 'fr' ? 'PROJET' : 'PROJECT' }}
        </v-btn> -->
      </div>
    </div>
    <!-- -------------------------------------------------  TABLE PROJET -------------------------------------------------  -->

    <div
      class="content-table-projet border-top-solid"
      :class="{ show: selectedModalFileComputed }"
    >
      <div class="content-onglet-projet">
        <div
          class="menu mr-3 border-right-solid"
          :class="{ 'menu-hidden': hiddenContentPipeline }"
        >
          <!----------------------------------------------------------- AFFICHAGE STATUT SOUS STATUT PROJET -------------------------------->
          <div
            class="projects-loader-pipeline"
            v-if="proccessingPipelineFilter || initLoading || getEtapesLoading"
          >
            <LoadingComponent />
          </div>
          <div
            class="content-pipeline mr-1"
            :class="{ hidden: hiddenContentPipeline || openModalMasseProjet }"
            v-if="
              !proccessingPipelineFilter && !initLoading && !getEtapesLoading
            "
          >
            <div
              v-for="(statut, index) in pipelineFilters"
              :key="index"
              class="pipeline"
            >
              <v-tooltip
                right
                content-class="tootltip-pipeline"
                z-index="10"
                :disabled="!hiddenContentPipeline"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="block-flex-align-space-between padding-statut"
                    @click="siwtchFilterPipeline(statut, index)"
                    :class="{
                      active:
                        currentFilterPipeline &&
                        currentFilterPipeline.id === statut.id,
                      hidden: hiddenContentPipeline
                    }"
                  >
                    <div class="block-flex-align-start statut">
                      <div
                        :title="statut.name"
                        v-if="!hiddenContentPipeline"
                        class="color-crm-bold font-sz-12 bold-700 font-text label-statut-stade"
                      >
                        {{
                          selectedEtape && selectedEtape.index
                            ? selectedEtape.index
                            : '1'
                        }}.{{ statut.order + 1 }}
                        <span>{{ statut.name }}</span>
                      </div>

                      <div
                        class="taille-count-statut"
                        :class="{ hidden: hiddenContentPipeline }"
                      >
                        <span
                          :title="
                            getProjectsTotalRowsTableBoard
                              ? getProjectsTotalRowsTableBoard
                              : '0'
                          "
                          class="count"
                          v-if="
                            statut &&
                              statut.id == null &&
                              !getProjectCountProcessing
                          "
                        >
                          {{
                            getProjectsTotalRowsTableBoard
                              ? getProjectsTotalRowsTableBoard
                              : '0'
                          }}
                        </span>
                        <span
                          class="count"
                          v-else
                          :title="
                            statut && statut.projets_count
                              ? statut.projets_count
                              : '0'
                          "
                        >
                          {{
                            statut && statut.projets_count
                              ? statut.projets_count
                              : '0'
                          }}
                        </span>
                      </div>
                    </div>
                    <template v-if="!hiddenContentPipeline">
                      <div class="flesh-right" v-if="statut.flech == false">
                        <v-icon class="color-crm-bold icon-flech"
                          >mdi-chevron-right</v-icon
                        >
                      </div>
                      <div class="flesh-right" v-if="statut.flech">
                        <v-icon class="color-crm-bold icon-flech"
                          >mdi-chevron-down</v-icon
                        >
                      </div>
                    </template>
                  </div>
                </template>
                <span>{{ statut.name }}</span>
              </v-tooltip>
              <!------------------------------------------- AFFICHAGE SOUS STATUT --------------------------------------------------------->
              <div v-if="statut.flech" class="m-t-b-8">
                <div
                  v-if="
                    getEtapesSubstageLoading &&
                      (!statut.subStages || !statut.subStages.length)
                  "
                  class="color-crm font-sz-12 font-text bold-700 text-center"
                >
                  {{ $t('loading') }}
                </div>
                <div
                  v-for="(sousStaut, j) in statut.subStages"
                  :key="j"
                  class="sous-statut"
                  @click="siwtchFilterPipelineSousStaut(sousStaut, statut)"
                  :class="{
                    active:
                      currentFilterPipelineSousStatut &&
                      currentFilterPipelineSousStatut.id === sousStaut.id
                  }"
                >
                  <v-tooltip
                    right
                    content-class="tootltip-pipeline"
                    z-index="10"
                    :disabled="!hiddenContentPipeline"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-on="on"
                        v-bind="attrs"
                        class="block-flex-align-space-between padding-sous-statut "
                        :class="{ hidden: hiddenContentPipeline }"
                      >
                        <div
                          class="font-sz-12 font-text label-statut"
                          v-if="!hiddenContentPipeline"
                          :title="sousStaut.name"
                        >
                          <span class="bold font-sz-12 font-text">
                            {{
                              selectedEtape && selectedEtape.index
                                ? selectedEtape.index
                                : '1'
                            }}.{{ statut.order + 1 }}.{{
                              sousStaut.order + 1
                            }}</span
                          >
                          <span class="lettre-space font-text font-sz-12">
                            {{ sousStaut.name }}</span
                          >
                        </div>
                        <div
                          class="taille-count-ss"
                          :class="{ hidden: hiddenContentPipeline }"
                        >
                          <span
                            class="count"
                            :title="
                              sousStaut && sousStaut.projets_count
                                ? sousStaut.projets_count
                                : '0'
                            "
                          >
                            {{
                              sousStaut && sousStaut.projets_count
                                ? sousStaut.projets_count
                                : '0'
                            }}
                          </span>
                        </div>
                      </div>
                    </template>
                    <span>{{ sousStaut.name }}</span>
                  </v-tooltip>
                </div>
                <div
                  class="ml-2"
                  v-if="
                    !getEtapesSubstageLoading &&
                      statut &&
                      (!statut.subStages || !statut.subStages.length)
                  "
                >
                  <div
                    class="font-sz-12 font-text label-statut padding-sous-statut"
                  >
                    {{ $i18n.locale === 'fr' ? 'Aucun stade' : 'No stage' }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!pipelineFilters || !pipelineFilters.length"
              class="div-aucun-list color-crm-bold bold-700 font-text"
              :class="{
                'rotation-hidden-content-pipeline': !hiddenContentPipeline
              }"
            >
              {{ $i18n.locale === 'fr' ? 'Aucun pipeline' : 'No pipeline' }}
            </div>
          </div>
          <div
            class="transition-pipeline"
            v-if="
              !proccessingPipelineFilter &&
                !initLoading &&
                !openModalMasseProjet
            "
          >
            <div
              v-if="!hiddenContentPipeline"
              class="flech-back-pipeline"
              @click="hiddenMenuPipeline"
            >
              <div class="icon-back">
                <v-icon class="cursor-pointer color-crm " font="mdi"
                  >mdi-chevron-left</v-icon
                >
              </div>
            </div>
            <div v-else class="flech-back-pipeline" @click="hiddenMenuPipeline">
              <div class="icon-back">
                <v-icon class="cursor-pointer color-crm" font="mdi"
                  >mdi-chevron-left</v-icon
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="content"
          :class="{
            'menu-hidden': hiddenContentPipeline || openModalMasseProjet
          }"
        >
          <!------------------------------------------------- AFFICHAGE HEADER DE TABLE + FILTRAGE ------------------------------------>
          <div
            class="block-flex-align-space-between border-bottom-solid padding-name-type-projet"
          >
            <div class="projects-loader-show-statut" v-if="initLoading">
              <LoadingComponent />
            </div>
            <div class="type-projet" v-else>
              <span class="color-crm-bold font-sz-16 font-text bold-700">
                {{ $i18n.locale === 'fr' ? 'Projet :' : 'Project :' }}
              </span>
              <span class="font-text font-sz-12 color-black bold-600">{{
                currentType && currentType.name
                  ? currentType.name
                  : $i18n.locale === 'fr'
                  ? 'Aucun Projet'
                  : 'No Project'
              }}</span>
            </div>

            <div class="flex-center">
              <!--------------------------------------------------  Button Lancer un Apppel   --------------------------------------->
              <div class="choisi-actions mr-2 color-gris">
                <v-menu
                  offset-y
                  origin="center center"
                  transition="scale-transition"
                  :nudge-bottom="8"
                  :nudge-left="0"
                  :nudge-width="15"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      @click="openCallManagementModal"
                    >
                      <span>
                        <font-awesome-icon
                          class="img-h-19 margin-svg"
                          :icon="['far', 'headset']"
                        />
                      </span>
                      <span
                        class="font-sz-14 font-text bold-700 mr-3 ml-2 label-btn"
                      >
                        {{
                          $i18n.locale === 'fr'
                            ? 'Lancer un appel'
                            : 'Make a call'
                        }}
                      </span>

                      <v-progress-circular
                        v-if="false"
                        indeterminate
                        color="#5C2DD3"
                        class="sz-loader-icon"
                      ></v-progress-circular>
                      <!-- <v-icon class="icon-flech ml-3" v-else
                        >mdi-chevron-down</v-icon
                      > -->
                    </div>
                  </template>
                </v-menu>
              </div>

              <!----------------------------------------------------Button  Choisir une action  -------------------------------------->
              <div class="choisi-actions">
                <v-menu
                  offset-y
                  v-model="openAction"
                  origin="center center"
                  transition="scale-transition"
                  :nudge-bottom="8"
                  :nudge-left="0"
                  :nudge-width="15"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <div v-bind="attrs" v-on="on">
                      <span>
                        <font-awesome-icon
                          class="img-h-19 margin-svg"
                          :icon="['far', 'computer-mouse-scrollwheel']"
                        />
                      </span>
                      <span
                        class="font-sz-14 font-text bold-700 mr-3 ml-2 label-btn"
                      >
                        {{
                          $i18n.locale === 'fr'
                            ? 'Choisir une action'
                            : 'choose an action'
                        }}</span
                      >
                      <v-progress-circular
                        v-if="false"
                        indeterminate
                        color="#5C2DD3"
                        class="sz-loader-icon"
                      ></v-progress-circular>
                      <v-icon class="icon-flech ml-3" v-else
                        >mdi-chevron-down</v-icon
                      >
                    </div>
                  </template>
                  <v-list class="list-type-projet-scroll">
                    <v-list-item class="list-type-projet-item">
                      <v-list-item-title
                        class=" list-type-onglet-projet block-flex-align-space-between"
                      >
                        <span
                          class="text-center mr-2 font-text font-sz-12 bold-700"
                        >
                          {{
                            $i18n.locale === 'fr' ? 'Aucun action' : 'No action'
                          }}
                        </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
          <div class="header-add-projet">
            <div
              class="projects-loader-show-statut mb-1 mt-1 ml-2"
              v-if="getPipelinesLoading || initLoading"
            >
              <LoadingComponent />
            </div>
            <div class="title-type-statut" v-else>
              <div class="title-statut flex center">
                <div>
                  {{
                    currentFilterPipeline && currentFilterPipeline.name
                      ? currentFilterPipeline.name + ' - '
                      : $i18n.locale === 'fr'
                      ? 'Aucun catégories de stade' + ' - ' + 'Aucun stade'
                      : 'No stage category' + ' - ' + 'No stage'
                  }}
                </div>

                <div
                  class="color-crm font-sz-12 font-text ml-1"
                  v-if="
                    currentFilterPipeline && currentFilterPipeline.id == null
                  "
                >
                  {{ $i18n.locale === 'fr' ? 'Tous stade' : 'All stages' }}
                </div>
                <div
                  class="color-crm font-sz-12 font-text ml-1"
                  v-if="
                    currentFilterPipeline &&
                      currentFilterPipeline.id != null &&
                      currentFilterPipelineSousStatut == null
                  "
                >
                  {{
                    $i18n.locale === 'fr'
                      ? ' Aucun stade sélectionné'
                      : ' No stage selected'
                  }}
                </div>
                <div
                  class="color-crm font-sz-12 font-text ml-1"
                  v-if="
                    currentFilterPipelineSousStatut &&
                      currentFilterPipelineSousStatut.id
                  "
                >
                  {{ currentFilterPipelineSousStatut.name }}
                </div>
              </div>
            </div>
            <!-- Loading -->
            <div
              v-if="getProjectsProcessing"
              class="color-crm font-sz-12 font-text bold-700 mr-3"
            >
              {{ $t('loading') }}
            </div>
            <!-- ERROR PROJET -->
            <div v-if="getProjectsError" class="error-msg mr-3">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
            <!-- ERROR CONTACT -->
            <div v-if="getContactsError" class="error-msg mr-3">
              <ul v-if="Array.isArray(getContactsError)">
                <li v-for="(e, index) in getContactsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getContactsError }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <!-- -------------------------------------------------  TABLE PROJET -------------------------------------------------  -->
          <div class="body-content">
            <v-card class="card-table-projet">
              <template>
                <!----------------------------------------------------------------- Loading  ---------------------------------------------------------------->
                <div class="loader-content-projet" v-if="initLoading">
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="#5C2DD3"
                  ></v-progress-circular>
                </div>
                <!-- TABLE PROJET DYNAMIQUE -->
                <div v-else>
                  <v-data-table
                    class="table-projet"
                    :class="{
                      'table-projet-filter':
                        formatedCustomFilters && formatedCustomFilters.length
                    }"
                    :headers="
                      $i18n.locale == 'fr'
                        ? computedHeaderColumnTable
                        : computedHeaderColumnTable
                    "
                    :items="computedTableBoard"
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    :server-items-length="getProjectsTotalRowsTableBoard"
                    @update:page="handlePaginationChange"
                    @update:items-per-page="changePerPage"
                    :footer-props="{
                      'items-per-page-options': [50, 100, 150]
                    }"
                    :calculate-widths="false"
                    hide-default-header
                    :fixed-header="true"
                    item-key="id"
                    :no-data-text="
                      getProjectsProcessing
                        ? $i18n.locale == 'fr'
                          ? 'Chargement... Veuillez patienter'
                          : 'Loading... Please wait'
                        : $i18n.locale == 'fr'
                        ? `Il n'y a aucune donnée à afficher dans ce tableau.`
                        : 'There is no data to display in this table.'
                    "
                  >
                    <!-- HEADERS TABLE -->
                    <template v-slot:[`header`]="{ props }">
                      <tr
                        :style="{
                          height: '50px'
                        }"
                        class="header-projets"
                      >
                        <th
                          v-for="header in props.headers"
                          :key="header.key"
                          :style="{ width: header.width }"
                          :class="{
                            'th-checked sticky-check':
                              header.key === 'check_all',
                            'w-125': header.key != 'check_all',
                            'sticky-date': header.key === 'date'
                          }"
                        >
                          <div v-if="header.key === 'check_all'">
                            <v-checkbox
                              class="checked-global"
                              v-model="selectAll"
                              @change="handleSelectedMultiProject(selectAll)"
                              color="#5C2DD3"
                              :value="true"
                              :unchecked-value="false"
                              hide-details
                            ></v-checkbox>
                          </div>
                          <div v-else>
                            <span class="mr-2 text-center">
                              {{ header.name }}</span
                            >
                          </div>
                          <div
                            class="extra-border"
                            v-if="header.key === 'date'"
                          ></div>
                        </th></tr
                    ></template>
                    <!-- BODY TABLE -->
                    <template v-slot:[`item`]="{ item }">
                      <tr
                        :key="item.id"
                        :style="{
                          height: '50px'
                        }"
                        @click.prevent.stop="handleSelectedFileProject(item)"
                      >
                        <template v-for="(value, key) in item">
                          <td
                            v-if="key == 'check_all'"
                            :key="'check_all' + key"
                            class="checked-table sticky-check"
                            @click="handleCellClick($event)"
                          >
                            <v-checkbox
                              class="checked-global"
                              v-model="item.checked"
                              @change="
                                handleSelectedMultiProjectParCheck(
                                  item.checked,
                                  item
                                )
                              "
                              color="#5C2DD3"
                              :value="true"
                              :unchecked-value="false"
                              hide-details
                            ></v-checkbox>
                          </td>
                          <td
                            class="custom-cell"
                            :key="'td-value' + key"
                            v-if="
                              key !== 'id' &&
                                key !== 'checked' &&
                                key != 'check_all'
                            "
                            :class="{ 'sticky-date': key == 'date' }"
                          >
                            <div class="main-td-content" v-if="key != 'date'">
                              {{ value ? value : '-' }}
                            </div>
                            <div class="main-td-content" v-if="key == 'date'">
                              {{ value ? dateValue(value) : '--/--/----' }}
                            </div>
                            <div class="sub-td-content" v-if="key == 'date'">
                              à
                              {{ value ? timeValue(value) : '--:--:--' }}
                            </div>
                            <div
                              class="extra-border"
                              v-if="key == 'date'"
                            ></div>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </template>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <!-------------------------------------------------------- Modal New project ------------------------------------>
    <!-- <portal to="new-project-modal">
      <NewProjectModal
        v-if="newProjectState === true"
        @close="closeModalNewProjet"
        @created="handleNewCreateProject"
        :currentType="currentType"
      ></NewProjectModal>
    </portal> -->
    <!-------------------------------------------------------- End Modal New project ------------------------------------>

    <!-------------------------------------------------------- Modal Masse project ------------------------------------>

    <portal to="project-modal">
      <MultiProjectModalStatic
        v-if="openModalMasseProjet && !readonly"
        key="multi-modal"
        :data="checkedProjectsMulti"
        :type="currentType"
        :etape="selectedEtape"
        @updateMultiChampsProjet="updateMultiChampsProjet"
        @close="handleCloseMultiProject"
      ></MultiProjectModalStatic>
    </portal>
    <!-------------------------------------------------------- End Modal Masse project ------------------------------------>

    <!-------------------------------------------------------- Modal Gestion des appels ------------------------------------>
    <portal to="Modal-Gestion-Appels">
      <ModalGestionAppels
        v-if="GestionAppels"
        @close="closeModalGestionAppels"
        :currentType="currentType"
        @created="handleCreateProject"
      >
      </ModalGestionAppels>
    </portal>
    <!-------------------------------------------------------- End Modal Appel Contact ------------------------------------>

    <!-------------------------------------------------------- Modal Fiche de projet ------------------------------------>

    <div class="modal-projet" :class="{ show: !selectedModalFileComputed }">
      <DialogFileProjet
        v-if="
          getProjectsExtraProject &&
            getProjectsExtraProject.checked &&
            getProjectsExtraProject.checked == true &&
            openModalFileProjet
        "
        key="side-modal-1"
        :data="getProjectsExtraProject"
        :readonly="readonly"
        @closeModal="closeModal"
      ></DialogFileProjet>
      <!-------------------------------------------------------- End Modal Fiche de projet ------------------------------------>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'table-projet',
  components: {
    ModalGestionAppels: () =>
      import('@/views/CRMILA26/Projects/ComunikContact/ModalGestionAppels.vue'),
    // NewProjectModal: () =>
    //   import('@/views/CRMILA26/Projects/Dialogs/NewProjectModal.vue'),
    DialogFileProjet: () =>
      import('@/views/CRMILA26/Projects/Dialogs/DialogFileProjet.vue'),
    MultiProjectModalStatic: () =>
      import('@/views/CRMILA26/Projects/Dialogs/MultiProjectModalStatic.vue'),
    LoadingComponent: () => import('@/components/ui/LoadingComponent')
  },
  data() {
    return {
      selectedEtape: this.$i18n.locale === 'fr' ? 'Aucune etape' : 'No step',
      openAction: false,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 50,
        totalItems: this.getProjectsTotalRowsTableBoard
      },
      GestionAppels: false,
      initLoading: true,
      currentType: null,
      currentFilterPipeline: null,
      currentFilterPipelineSousStatut: null,
      pipelineFilters: [],
      customFilters: [],
      formatedCustomFilters: [],
      filters: [],
      conditions: {},
      selectAll: false,
      newProjectState: false,
      currentPage: 1,
      currentPipelineIndex: 'tab-0',
      proccessingPipelineFilter: false,
      sortDirection: 'asc',
      currentSort: 'creation_date',
      loading: false,
      messageUplaodExcel: false,
      filterToUpdate: null,
      perPage: 50,
      hiddenContentPipeline: false,
      openModalMasseProjet: false,
      openModalFileProjet: false,
      checkedProjectsOne: null,
      checkedProjectsMulti: []
    }
  },
  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'updateProject',
      'fetchSingleProject',
      'exportProjectFile',
      'resetErrorProject',
      'resetErrorContact',
      'resetPageValue',
      'fetchEtapes',
      'fetchEtapeStage',
      'fetchEtapeSubstage',
      'fetchHeaderColumnTableBoard',
      'fetchTableBoard',
      'refreshProjectExtra',
      'intialiserTableProjet',
      'setCurentTypeProject',
      'setLoaderThemeForProject'
    ]),
    async handelopenModalFileProjet() {
      if (
        this.$route.name == 'project' &&
        this.$route.params &&
        this.$route.params.id &&
        (!this.getProjectsExtraProject || !this.getProjectsExtraProject.length)
      ) {
        await this.fetchSingleProject({
          id: this.$route.params.id,
          checked: true
        }).then(() => {
          if (this.getProjectsExtraProject) {
            if (
              this.getCurentProjectType?.id !=
              this.getProjectsExtraProject?.type_id
            ) {
              let step_object = this.getProjectsTypes.find(
                i => i.id == this.getProjectsExtraProject.type_id
              )
              this.setCurentTypeProject(step_object)
            }

            this.getProjectsExtraProject.checked = true
            this.openModalFileProjet = true
            this.setLoaderThemeForProject(false)
            this.openModalMasseProjet = false
            this.checkedProjectsOne = this.getProjectsExtraProject
            this.currentType = this.getCurentProjectType
            // if (localStorage.getItem('typeProject')) {
            //   if (this.getProjectsTypes && this.getProjectsTypes.length) {
            //     for (
            //       let index = 0;
            //       index < this.getProjectsTypes.length;
            //       index++
            //     ) {
            //       if (
            //         this.getProjectsTypes[index] &&
            //         this.getProjectsTypes[index].id ==
            //           localStorage.getItem('typeProject')
            //       ) {
            //         this.currentType = this.getProjectsTypes[index]
            //       }
            //     }
            //   }
            // } else {
            //   this.currentType = this.getProjectsTypes[0]
            //   if (this.currentType) {
            //     localStorage.setItem('typeProject', this.currentType.id)
            //   }
            // }
          }
        })
      }
    },
    async refreshListEtapeProjet(isNotLoading) {
      if (this.getProjectsTypes && this.getProjectsTypes.length) {
        await this.fetchEtapes({
          type_id:
            this.currentType && this.currentType.id
              ? this.currentType.id
              : this.getProjectsTypes[0].id,
          isNotLoading: isNotLoading
        }).then(async () => {
          this.selectedEtape =
            this.getEtapes && this.getEtapes.length
              ? this.getEtapes[0]
              : this.$i18n.locale === 'fr'
              ? 'Aucune etape'
              : 'No step'
          this.proccessingPipelineFilter = isNotLoading ? false : true
          if (this.getEtapes && this.getEtapes.length) {
            await this.fetchEtapeStage({
              step_id: this.selectedEtape.id,
              etape: this.selectedEtape,
              isNotLoading: isNotLoading
            }).then(() => {
              this.setPipeLineFilters()
            })
          } else {
            this.pipelineFilters = []
            this.currentFilterPipeline = null
          }
          this.proccessingPipelineFilter = false
        })
      }
    },
    async refreshTableProjet() {
      await this.fetchHeaderColumnTableBoard(
        this.currentType && this.currentType.id
          ? this.currentType.id
          : this.currentType
      ).then(async () => {
        if (this.selectedEtape && this.selectedEtape.id) {
          await this.fetchTableBoard({
            type_id:
              this.currentType && this.currentType.id
                ? this.currentType.id
                : this.currentType,
            per_page: this.perPage,
            page: this.pagination.page,
            step_id: this.selectedEtape.id,
            stage_id:
              this.currentFilterPipeline && this.currentFilterPipeline.id
                ? this.currentFilterPipeline.id
                : this.currentFilterPipeline
          })
        }
      })
    },
    async refreshList(value) {
      if (!value) {
        this.changeEtapes(this.selectedEtape, false)
        if (JSON.parse(localStorage.getItem('etape'))) {
          this.selectedEtape = JSON.parse(localStorage.getItem('etape'))
        }
      } else {
        let etapeSave = { ...this.selectedEtape }
        localStorage.setItem('etape', JSON.stringify(etapeSave))
      }
      this.$nextTick(() => {
        if (
          this.getEtapes &&
          this.getEtapes.length &&
          this.checkedProjectsOne
        ) {
          this.getEtapes.forEach(etape => {
            etape.flech = false
            if (
              this.checkedProjectsOne.pipeline &&
              etape.id === this.checkedProjectsOne.pipeline.id
            ) {
              etape.flech = true

              if (
                etape.stages &&
                etape.stages.length &&
                this.checkedProjectsOne &&
                this.checkedProjectsOne.stage
              ) {
                etape.stages.forEach(stage => {
                  if (stage.id === this.checkedProjectsOne.stage_id) {
                    stage.flech = true
                  } else {
                    stage.flech = false
                  }
                })
              }
            }
          })
        }
      })
    },
    closeModalGestionAppels() {
      this.GestionAppels = false
    },
    // Modal New Project
    closeModalNewProjet() {
      this.newProjectState = false
      if (this.getProjectsTypes && this.getProjectsTypes.length) {
        this.fetchEtapes({
          type_id:
            this.currentType && this.currentType.id
              ? this.currentType.id
              : this.getProjectsTypes[0].id,
          isNotLoading: true
        })
      }
    },
    async handleNewCreateProject(project) {
      await this.refreshProjectExtra()
      if (project && project.id) {
        this.$router.push('/projects/' + project.id)
        // REFRESH API FILE PROJET
        if (
          this.$route.name == 'project' &&
          this.$route.params &&
          this.$route.params.id &&
          (!this.getProjectsExtraProject ||
            !this.getProjectsExtraProject.length)
        ) {
          this.fetchSingleProject({
            id: project.id,
            checked: true
          }).then(() => {
            if (this.getProjectsExtraProject) {
              this.getProjectsExtraProject.checked = true
              this.openModalFileProjet = true
              this.openModalMasseProjet = false
              this.checkedProjectsOne = this.getProjectsExtraProject
              if (localStorage.getItem('typeProject')) {
                if (this.getProjectsTypes && this.getProjectsTypes.length) {
                  for (
                    let index = 0;
                    index < this.getProjectsTypes.length;
                    index++
                  ) {
                    if (
                      this.getProjectsTypes[index] &&
                      this.getProjectsTypes[index].id ==
                        localStorage.getItem('typeProject')
                    ) {
                      this.currentType = this.getProjectsTypes[index]
                    }
                  }
                }
              } else {
                this.currentType = this.getProjectsTypes[0]
                if (this.currentType) {
                  localStorage.setItem('typeProject', this.currentType.id)
                }
              }
            }
          })
        }
      }
      this.newProjectState = false
    },

    async changeEtapes(etape, openFileProjet) {
      // CAS OPENMODAL MASSE
      if (this.openModalMasseProjet) {
        this.selectAll = false
        this.handleSelectedMultiProject(this.selectAll)
      }
      this.proccessingPipelineFilter = true
      this.selectedEtape = etape
      // FERMER LE MENU DE LIST ETAPE
      if (this.$refs.menuEtapeProjet) {
        this.$refs.menuEtapeProjet.isActive = false
      }
      if (this.getEtapes && this.getEtapes.length) {
        await this.fetchEtapeStage({
          step_id: this.selectedEtape.id,
          etape: this.selectedEtape,
          isNotLoading: true
        }).then(() => {
          if (etape && etape.stages && etape.stages.length) {
            this.setPipeLineFiltersIndexEtape(etape)
          } else {
            this.pipelineFilters = []
            this.currentFilterPipeline = null
          }
        })
      } else {
        this.pipelineFilters = []
        this.currentFilterPipeline = null
      }
      // REFRESH TABLE PROJET
      if (!openFileProjet) {
        await this.fetchTableBoard({
          type_id:
            this.currentType && this.currentType.id
              ? this.currentType.id
              : this.currentType,
          per_page: this.perPage,
          page: this.pagination.page,
          step_id: this.selectedEtape.id,
          stage_id:
            this.currentFilterPipeline && this.currentFilterPipeline.id
              ? this.currentFilterPipeline.id
              : this.currentFilterPipeline
        })
      }
      this.proccessingPipelineFilter = false
    },
    async setNewStageWithSub(stage, subStages) {
      if (
        this.$route &&
        this.$route.name == 'project' &&
        this.$route.params &&
        this.$route.params.id
      ) {
        stage.flech = true
        this.resetPageValue({ object: 'tousWithjournal' })
        await this.updateProject({
          entity: this.getProjectsExtraProject
            ? this.getProjectsExtraProject
            : this.checkedProjectsOne,
          field: 'cstage_id',
          value: stage.id,
          substage: {
            field: 'csubstage_id',
            value: subStages && subStages.id ? subStages.id : null
          },
          step_update: {
            field: 'step_id',
            value:
              this.selectedEtape && this.selectedEtape.id
                ? this.selectedEtape.id
                : null
          }
        })
      }
    },
    openCallManagementModal() {
      this.GestionAppels = true
    },
    hiddenMenuPipeline() {
      this.hiddenContentPipeline = !this.hiddenContentPipeline
    },
    rotateFlechStep(step) {
      this.getEtapes.map(item => {
        if (step.id == item.id) {
          step.flech = !step.flech
        } else {
          item.flech = false
        }
      })
      if (step.flech) {
        this.changeEtapes(step, true)
      }
    },
    rotateFlechPipeline(statut) {
      statut.flech = !statut.flech
      if (statut && statut.flech) {
        this.fetchEtapeSubstage({
          cstage_id: statut.id,
          stage: statut
        })
      }
    },
    handleCellClick(event) {
      event.stopPropagation()
    },
    async handleSelectedFileProject(projet) {
      projet.checked = true
      this.selectAll = true
      this.checkedProjectsOne = projet
      if (
        this.$route &&
        this.$route.name != 'project' &&
        !this.$route.params.id
      ) {
        this.$router.push('/projects/' + projet.id)
      }
      await this.fetchSingleProject({
        id: projet.id,
        checked: true
      }).then(() => {
        this.getProjectsExtraProject.checked = true
        if (localStorage.getItem('typeProject')) {
          if (this.getProjectsTypes && this.getProjectsTypes.length) {
            for (let index = 0; index < this.getProjectsTypes.length; index++) {
              if (
                this.getProjectsTypes[index] &&
                this.getProjectsTypes[index].id ==
                  localStorage.getItem('typeProject')
              ) {
                this.currentType = this.getProjectsTypes[index]
              }
            }
          }
        } else {
          this.currentType = this.getProjectsTypes[0]
          if (this.currentType) {
            localStorage.setItem('typeProject', this.currentType.id)
          }
        }
      })
      this.openModalFileProjet = true
      this.openModalMasseProjet = false
    },
    async handleSelectedMultiProject(checkAll) {
      this.openModalFileProjet = false
      if (this.$route && this.$route.path != '/projects') {
        this.$router.replace('/projects')
      }
      if (checkAll) {
        this.selectAll = true
        this.computedTableBoard.forEach(item => {
          item.checked = this.selectAll
        })
        this.checkedProjectsMulti = this.computedTableBoard.filter(
          item => item.checked == true
        )
        this.openModalMasseProjet = true
      } else {
        this.selectAll = false
        this.computedTableBoard.forEach(item => {
          item.checked = this.selectAll
        })
        this.checkedProjectsMulti = this.computedTableBoard.filter(
          item => item.checked == true
        )
        this.openModalMasseProjet = false
      }
    },
    async handleSelectedMultiProjectParCheck(check, projetCheck) {
      this.openModalFileProjet = false
      if (this.$route && this.$route.path != '/projects') {
        this.$router.replace('/projects')
      }
      this.computedTableBoard.forEach(item => {
        if (item.id == projetCheck.id) {
          item.checked = check
        }
      })
      this.checkedProjectsMulti = this.computedTableBoard.filter(
        item => item.checked == true
      )
      this.selectAll =
        !this.checkedProjectsMulti || !this.checkedProjectsMulti.length
          ? false
          : true
      if (this.selectAll) {
        this.openModalMasseProjet = true
      } else {
        this.openModalMasseProjet = false
      }
    },
    async updateMultiChampsProjet() {
      if (this.getProjectsTypes && this.getProjectsTypes.length) {
        await this.fetchEtapes({
          type_id:
            this.currentType && this.currentType.id
              ? this.currentType.id
              : this.getProjectsTypes[0].id,
          isNotLoading: true
        }).then(async () => {
          if (this.getEtapes && this.getEtapes.length) {
            await this.fetchEtapeStage({
              step_id: this.selectedEtape.id,
              etape: this.selectedEtape,
              isNotLoading: true
            }).then(() => {
              this.setPipeLineFilters()
            })
          } else {
            this.pipelineFilters = []
            this.currentFilterPipeline = null
          }
        })
      }
      this.refreshTableProjet()
    },
    async handleCloseMultiProject() {
      this.selectAll = false
      this.handleSelectedMultiProject(this.selectAll)
      // this.updateMultiChampsProjet()
    },
    async changeTypeProjet(type) {
      this.initLoading = true
      this.selectAll = false
      this.currentFilterPipelineSousStatut = null
      this.currentType = type
      localStorage.setItem('typeProject', this.currentType.id)
      await this.refreshListEtapeProjet().then(() => {
        this.refreshTableProjet()
      })
      this.currentPage = 1
      this.currentPipelineIndex = 0
      this.initLoading = false
    },
    closeModal() {
      this.refreshTableProjet()
      this.resetErrorProject()
      this.resetErrorContact()
      this.openModalFileProjet = false
      this.selectAll = false
      if (this.$route && this.$route.path != '/projects') {
        this.$router.replace('/projects')
      }
      if (this.computedTableBoard && this.computedTableBoard.length) {
        this.computedTableBoard.forEach(item => {
          item.checked = this.selectAll
        })
      }
      if (this.checkedProjectsOne) {
        this.checkedProjectsOne.checked = false
        this.getProjectsExtraProject.checked == false
      }
      if (!this.getEtapes || !this.getEtapes.length) {
        this.refreshListEtapeProjet()
      }
    },
    createNewProject() {
      this.newProjectState = true
    },
    async siwtchFilterPipelineSousStaut(sousStatut, statut) {
      this.currentFilterPipeline = statut
      this.currentFilterPipelineSousStatut = sousStatut
      this.currentPage = 1
      await this.fetchTableBoard({
        type_id:
          this.currentType && this.currentType.id
            ? this.currentType.id
            : this.currentType,
        per_page: this.perPage,
        page: this.pagination.page,
        step_id: this.selectedEtape.id,
        substage_id: this.currentFilterPipelineSousStatut.id
      })
    },
    async siwtchFilterPipeline(filter, index) {
      filter.flech = !filter.flech
      this.fetchEtapeSubstage({
        cstage_id: filter.id,
        stage: filter
      })
      this.currentFilterPipeline = filter
      this.currentFilterPipelineSousStatut = null
      this.fetchTableBoard({
        type_id:
          this.currentType && this.currentType.id
            ? this.currentType.id
            : this.currentType,
        per_page: this.perPage,
        page: this.pagination.page,
        step_id: this.selectedEtape.id,
        stage_id:
          this.currentFilterPipeline && this.currentFilterPipeline.id
            ? this.currentFilterPipeline.id
            : this.currentFilterPipeline
      })
      this.currentPage = 1
      this.currentPipelineIndex = index
    },
    async handleCreateProject(project) {
      if (project && project.type_id === this.currentType.id) {
        this.handleSelectedFileProjectDynamic(project)
      } else {
        this.$router.push('/projects/' + project.id)
      }
      this.newProjectState = false
    },
    setPipeLineFilters(pipelineIndex = 0) {
      if (this.currentType && this.currentType.id) {
        if (this.getEtapes && this.getEtapes.length) {
          for (let index = 0; index < this.getEtapes.length; index++) {
            if (
              this.getEtapes[index] &&
              this.getEtapes[index].type.some(
                element => element.id === this.currentType.id
              ) &&
              this.getEtapes[index].id == this.selectedEtape.id
            ) {
              this.pipelineFilters = [...this.getEtapes[index].stages]
              this.currentFilterPipeline = this.pipelineFilters[pipelineIndex]
              return
            }
          }
        }
        this.pipelineFilters = []
        this.currentFilterPipeline = null
      }
    },
    setPipeLineFiltersIndexEtape(etape, pipelineIndex = 0) {
      if (this.currentType && this.currentType.id) {
        if (
          etape &&
          etape.type.some(element => element.id === this.currentType.id)
        ) {
          this.pipelineFilters = [...etape.stages]
          this.currentFilterPipeline = this.pipelineFilters[pipelineIndex]
          return
        }
        this.pipelineFilters = []
        this.currentFilterPipeline = null
      }
    },
    updateCustomFilters() {
      this.formatedCustomFilters = []
      for (const property in this.customFilters) {
        if (
          this.customFilters[property] &&
          this.customFilters[property].value
        ) {
          this.formatedCustomFilters.push(this.customFilters[property])
        }
      }
      localStorage.setItem('filter', JSON.stringify(this.formatedCustomFilters))
      localStorage.removeItem('filterCreer')
    },
    updateDossier(value, field, entity) {
      this.updateProject({
        entity: entity,
        field: field,
        value: value ? 1 : 0,
        checked: false
      })
    },
    resetCustomFilter() {
      this.customFilters = []
      this.formatedCustomFilters = []
      this.updateCustomFilters()
      this.currentPage = 1
    },
    changePerPage(newPerPage) {
      this.page = 1
      this.pagination.itemsPerPage = newPerPage
      this.pagination.page = 1
      this.perPage = newPerPage
      this.handlePaginationChange(this.page)
      this.selectAll = false
    },
    handlePaginationChange(page) {
      this.pagination.page = page
      if (this.selectedEtape && this.selectedEtape.id) {
        if (this.sortDirection === 'asc') {
          this.fetchTableBoard({
            per_page: this.perPage,
            page: page,
            step_id: this.selectedEtape.id,
            type_id:
              this.currentType && this.currentType.id
                ? this.currentType.id
                : this.currentType,
            stage_id:
              this.currentFilterPipeline && this.currentFilterPipeline.id
                ? this.currentFilterPipeline.id
                : this.currentFilterPipeline,
            substage_id:
              this.currentFilterPipelineSousStatut &&
              this.currentFilterPipelineSousStatut.id
                ? this.currentFilterPipelineSousStatut.id
                : this.currentFilterPipelineSousStatut
          })
        } else {
          this.fetchTableBoard({
            per_page: this.perPage,
            page: page,
            step_id: this.selectedEtape.id,
            type_id:
              this.currentType && this.currentType.id
                ? this.currentType.id
                : this.currentType,
            stage_id:
              this.currentFilterPipeline && this.currentFilterPipeline.id
                ? this.currentFilterPipeline.id
                : this.currentFilterPipeline,
            substage_id:
              this.currentFilterPipelineSousStatut &&
              this.currentFilterPipelineSousStatut.id
                ? this.currentFilterPipelineSousStatut.id
                : this.currentFilterPipelineSousStatut
          })
        }
      }
    },

    exportExcel() {
      let ObjectExport = {}

      if (
        this.currentSort == 'creation_date' ||
        this.currentSort == 'closing_date' ||
        this.currentSort == 'date_debut_formation'
      ) {
        if (this.sortDirection === 'asc') {
          ObjectExport = {
            sort_by_desc: this.currentSort
          }
        } else {
          ObjectExport = {
            sort_by: this.currentSort
          }
        }
      } else {
        if (this.sortDirection === 'asc') {
          ObjectExport = {
            sort_by: this.currentSort
          }
        } else {
          ObjectExport = {
            sort_by_desc: this.currentSort
          }
        }
      }
      ObjectExport.type_id = this.currentType.id
      ObjectExport.step_id = this.selectedEtape.id
      this.exportProjectFile(ObjectExport)
    },
    importExcel() {
      this.$refs.fileInput.value = null
      this.$refs.fileInput.click()
    },
    async handleFileUpload(e) {
      let files = e.target.files[0]
      const response = await this.uploadFileExcel({
        file: files,
        type_id: this.currentType.id
      })
      if (response) {
        this.fetchTableBoard({
          per_page: this.perPage,
          page: this.currentPage,
          step_id: this.selectedEtape.id,
          type_id:
            this.currentType && this.currentType.id
              ? this.currentType.id
              : this.currentType
        })
        this.messageUplaodExcel = true
        setTimeout(() => {
          this.messageUplaodExcel = false
        }, 10000)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsExportProcessing',
      'getProjectsUploadProcessing',
      'getTableBoard',
      'getProjectsExtraProject',
      'getProjectsTypes',
      'getProjectsProcessing',
      'getProjectsError',
      'getContactsError',
      'getPipelinesLoading',
      'currentUser',
      'getProjectsTotalRowsTableBoard',
      'getProjectCountProcessing',
      'getEtapes',
      'getEtapesLoading',
      'getEtapesSubstageLoading',
      'getHeaderTable',
      'getCurentProjectType'
    ]),
    dateValue() {
      return function(value) {
        return value.split(' ')[0]
      }
    },
    timeValue() {
      return function(value) {
        return value.split(' ')[1]
      }
    },
    computedHeaderColumnTable: function() {
      if (this.getHeaderTable && this.getHeaderTable.length) {
        const additionalHeader = {
          name: '',
          align: 'start',
          key: 'check_all',
          width: '40px !important'
        }

        // Créez une copie de this.getHeaderTable avant d'y ajouter l'objet supplémentaire
        const updatedHeaders = [additionalHeader, ...this.getHeaderTable]

        return updatedHeaders
      }

      return []
    },
    computedTableBoard: function() {
      if (this.getTableBoard && this.getTableBoard.length) {
        const newArray = this.getTableBoard.map(obj => ({
          check_all: false,
          checked: false,
          ...obj
        }))
        return newArray
      }

      return []
    },
    selectedModalFileComputed: function() {
      if (
        this.$route &&
        (this.$route.name == 'project' ||
          this.$route.name == 'project_activity')
      ) {
        return true
      } else {
        return false
      }
    },
    readonly: function() {
      if (
        this.currentUser &&
        (this.currentUser.type == 'user.commercial' ||
          this.currentUser.type == 'user.final' ||
          (this.currentUser.type == 'user.admin' &&
            this.currentUser.details &&
            this.currentUser.details.team &&
            this.currentUser.details.team.data &&
            this.currentUser.details.team.data.type == 'regie'))
      ) {
        return true
      }
      return false
    },
    visibilityExportExcel: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.has_export_excel == 1
      ) {
        return true
      }
      return false
    },
    visibilityImportExcel: function() {
      if (
        this.currentUser &&
        this.currentUser.details &&
        this.currentUser.details.has_import_excel == 1
      ) {
        return true
      }
      return false
    }
  },
  watch: {
    getCurentProjectType: {
      handler() {
        this.changeTypeProjet(this.getCurentProjectType)
      },
      deep: true
    },

    // async $route(route) {
    //   // intialiser le checked dans table lors de fermer le modal de fiche de projet
    //   if (route && route.path == '/projects') {
    //     if (this.selectAll) {
    //       this.computedTableBoard.forEach(item => {
    //         item.checked = false
    //       })
    //     }
    //     if (this.getProjectsExtraProject) {
    //       this.getProjectsExtraProject.checked = true
    //     }
    //     this.selectAll = false
    //   }
    //   // pour faire unr recherche un projet dans search global
    //   // lors ouvrir notification activity
    //   if (
    //     route &&
    //     (route.name == 'project' || route.name == 'project_activity') &&
    //     route.params &&
    //     route.params.id
    //   ) {
    //     this.fetchSingleProject({
    //       id: this.$route.params.id,
    //       checked: true
    //     }).then(() => {
    //       if (this.getProjectsExtraProject) {
    //         this.getProjectsExtraProject.checked = true
    //         this.openModalFileProjet = true
    //         this.openModalMasseProjet = false
    //         this.checkedProjectsOne = this.getProjectsExtraProject
    //         if (localStorage.getItem('typeProject')) {
    //           if (this.getProjectsTypes && this.getProjectsTypes.length) {
    //             for (
    //               let index = 0;
    //               index < this.getProjectsTypes.length;
    //               index++
    //             ) {
    //               if (
    //                 this.getProjectsTypes[index] &&
    //                 this.getProjectsTypes[index].id ==
    //                   localStorage.getItem('typeProject')
    //               ) {
    //                 this.currentType = this.getProjectsTypes[index]
    //               }
    //             }
    //           }
    //         } else {
    //           this.currentType = this.getProjectsTypes[0]
    //           if (this.currentType) {
    //             localStorage.setItem('typeProject', this.currentType.id)
    //           }
    //         }
    //       }
    //     })
    //   }
    // },
    checkedProjectsOne() {
      if (this.getEtapes && this.getEtapes.length && this.checkedProjectsOne) {
        this.getEtapes.forEach(etape => {
          if (
            this.checkedProjectsOne.pipeline &&
            etape.id === this.checkedProjectsOne.pipeline.id
          ) {
            etape.flech = true

            if (
              etape.stages &&
              etape.stages.length &&
              this.checkedProjectsOne &&
              this.checkedProjectsOne.stage
            ) {
              etape.stages.forEach(stage => {
                if (stage.id === this.checkedProjectsOne.stage_id) {
                  stage.flech = true
                }
              })
            }
          }
        })
      }
    },
    async $route(route) {
      if (route.name == 'projects') {
        this.checkedProjectsOne = null
        this.selectAll = false
        localStorage.removeItem('etape')
        this.intialiserTableProjet()
        //  FETCH LIST TABLE PROJET
        this.refreshListEtapeProjet().then(() => {
          this.refreshTableProjet()
        })
      }
      if (route.name == 'project' && route.params.id) {
        this.handelopenModalFileProjet()
      }
    }
  },
  async mounted() {
    this.initLoading = true
    // REFRESH API FILE PROJET
    await this.handelopenModalFileProjet()
    localStorage.removeItem('etape')
    // FETCH LIST TYPE PROJET
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes({
        exclude: ['fields']
      })
    }
    // AFFECTER ID TYPE PROJET
    if (localStorage.getItem('typeProject')) {
      if (this.getProjectsTypes && this.getProjectsTypes.length) {
        for (let index = 0; index < this.getProjectsTypes.length; index++) {
          if (
            this.getProjectsTypes[index] &&
            this.getProjectsTypes[index].id ==
              localStorage.getItem('typeProject')
          ) {
            this.currentType = this.getProjectsTypes[index]
          }
        }
      }
    } else {
      this.currentType = this.getProjectsTypes[0]
      if (this.currentType) {
        localStorage.setItem('typeProject', this.currentType.id)
      }
    }
    //  FETCH LIST TABLE PROJET
    await this.refreshListEtapeProjet().then(() => {
      this.refreshTableProjet()
    })

    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.contact {
  margin-left: 10px;
}
.page-content {
  position: relative;

  //   padding: 16px;
  //   height: 100%;
  //   width: 100%;
  //   max-width: 1300px;
  //   margin: 0 auto;
  .action-btn-projet {
    .btn-filter {
      border-radius: 4px;
      padding: 1px 0px 0px 0px;
      font-size: 12px;

      &.hidden {
        display: none;
      }

      .sz-icon-filter {
        font-size: 20px !important;
      }
    }

    .btn-add-projet {
      border-radius: 4px;
      padding: 6px 16px 6px 10px;
      font-size: 12px;

      .sz-icon-plus {
        font-size: 20px !important;
      }
    }
  }

  .add-projet-filter {
    margin: 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.show {
      display: none;
    }
  }

  .etapes-leads,
  .choisi-actions {
    border-radius: 3px;
    background-color: transparent;
    padding: 5px 7px 5px 7px;
    border: 1px solid #e5e5e5;
    color: #2f2f2f;
    white-space: nowrap;
    cursor: pointer;

    .label-btn {
      letter-spacing: 1%;
      line-height: 17.07px;
    }

    &.color-gris {
      background-color: #f6f6f6;
    }

    .margin-svg {
      margin-bottom: -3px;
    }
  }

  .content-table-projet {
    display: block;

    &.show {
      display: none;
    }
  }

  .modal-projet {
    display: block;

    &.show {
      display: none;
    }
  }

  .block-type-projet {
    margin: 8px 8px;

    .list-type {
      border-radius: 5px;
      background-color: #f6f6f6;
      width: 100%;
      padding: 4px 6px 4px 6px;
      border: 1px solid #3a167f;
      color: #000000;
      margin: 3px;
      font-size: 11px;
      white-space: nowrap;
      cursor: pointer;

      &.active {
        background-color: #3a167f;
        color: #fff;

        .count {
          padding: 3px 3px 1px 3px;
          background-color: #fff;
          color: #3a167f;
          font-size: 11px;
          border-radius: 4px;
        }
      }

      .count {
        padding: 3px 3px 1px 3px;
        background-color: #3a167f;
        color: #fff;
        font-size: 11px;
        border-radius: 4px;
      }

      .color-active {
        color: #fff !important;
      }
    }

    .btn-arrows {
      cursor: pointer;
      color: #fff !important;
      background-color: #3a167f;
    }

    .color-white {
      color: #fff !important;
    }
  }

  .content-onglet-projet {
    .menu {
      cursor: pointer;
      white-space: normal;
      max-height: 100%;
      // height: 94%;
      max-height: calc(100vh - 167px) !important;
      height: calc(100vh - 167px) !important;
      overflow-y: auto;
      overflow-x: hidden;

      .projects-loader-type {
        display: block;
        width: 100%;
        height: 100%;
      }

      .transition-pipeline {
        .flech-back-pipeline {
          border-right: 0px !important;
          padding: 25px 0px;
          border: 0px solid #5c2dd3;
          border-radius: 6px 0px 0px 6px;
          background-color: #5c2dd32e;
        }

        .icon-back {
          transform: rotateY(0deg);
        }
      }

      .margin {
        margin-right: 4px;
      }
    }

    .menu {
      position: absolute;
      width: 280px;
      background-color: #f6f6f6;
      transform: translateX(0px);
      transition: 0s all ease;
      transition-timing-function: ease-out;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // padding-right: 15px;
      &.menu-hidden {
        // width: 94px;
        width: 23px;
        transform: translateX(0px);
        transition: 0.3s all ease;
        transition-timing-function: ease-out;

        .flech-back-pipeline {
          border: 0px solid #5c2dd3;
          border-left: 0px !important;
          border-right: 0px solid #5c2dd3 !important;
          border-radius: 0px 6px 6px 0px;

          .icon-back {
            transform: rotateY(180deg);
          }
        }
      }

      .content-pipeline::-webkit-scrollbar {
        height: 7px;
        width: 7px;
        direction: rtl;
      }

      .content-pipeline::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #80808054;
        border-radius: 10px;
        direction: rtl;
      }

      .content-pipeline::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 10px;
        direction: rtl;
      }

      .content-pipeline::-webkit-scrollbar-thumb:hover {
        background: #888;
        direction: rtl;
      }

      .content-pipeline {
        direction: rtl;
        width: 270px;
        height: 100%;
        padding: 10px 15px 0px 20px;
        white-space: normal;
        max-height: 100%;
        max-height: calc(100vh - 225px) !important;
        height: calc(100vh - 225px) !important;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;

        &.hidden {
          display: none;
          padding: 10px 0px 0px 8px;
        }

        .pipeline {
          direction: ltr;

          .padding-statut {
            padding: 6px 4px;
            border-bottom: 1px solid darkblue;

            &.hidden {
              padding: 4px;
            }

            &:hover,
            &.active {
              background-color: #ded7f0;
            }
          }

          .taille-count-statut {
            width: 8%;
            line-height: 13px;
            position: relative;
            left: -14px;

            &.hidden {
              margin-left: 3px;
            }

            .count {
              height: 23px;
              padding: 5px 4px 4px 4px !important;
              background-color: #3a167f;
              color: #fff;
              min-width: 20px;
              max-width: 35px !important;
              text-align: center;
              display: inline-block;
              font-size: 11px !important;
              border-radius: 4px !important;
              -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
              flex: 1 1 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .statut {
            &.active {
              background-color: #3a167f;
              color: #fff;

              .count {
                height: 22px;
                padding: 5px 6px 6px 6px;
                background-color: #fff;
                color: #3a167f;
                min-width: 20px;
                max-width: 43px;
                display: inline-block;
                font-size: 11px !important;
                border-radius: 4px !important;
                -webkit-box-flex: 1;
                -ms-flex: 1 1 100%;
                flex: 1 1 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .count {
              height: 23px;
              padding: 5px 6px 4px 6px;
              background-color: #3a167f;
              color: #fff;
              min-width: 20px;
              max-width: 43px;
              display: inline-block;
              font-size: 11px !important;
              border-radius: 4px !important;
              -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
              flex: 1 1 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .color-active {
              color: #fff !important;
            }
          }

          .icon-flech {
            font-size: 20px !important;

            &:hover {
              background-color: #a791e382;
              border-radius: 20px;
            }
          }

          .taille-count-ss {
            width: 20%;

            &.hidden {
              width: 100%;
            }

            .count {
              text-align: center;
              height: 22px;
              padding: 6px 5px 5px 5px;
              background-color: #704ad1;
              color: #fff;
              min-width: 20px;
              max-width: 36px;
              display: inline-block;
              font-size: 11px !important;
              border-radius: 4px !important;
              -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
              flex: 1 1 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .sous-statut {
            .padding-sous-statut {
              padding: 6px 0px 4px 6px;
              line-height: 12px;

              &.hidden {
                padding: 6px 0px 4px 8px;
              }
            }

            &.active,
            &:hover {
              background-color: #ded7f0;
              // color: #fff;
              // .count {
              //   padding: 3px 3px 1px 3px;
              //   background-color: #fff;
              //   color: #3a167f;
              //   font-size: 11px;
              //   border-radius: 4px;
              // }
            }

            .count {
              text-align: center;
              height: 22px;
              padding: 6px 6px 6px 6px;
              background-color: rgba(112, 74, 209, 1);
              color: #fff;
              min-width: 20px;
              max-width: 100%;
              display: inline-block;
              font-size: 11px !important;
              border-radius: 4px !important;
              -webkit-box-flex: 1;
              -ms-flex: 1 1 100%;
              flex: 1 1 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .label-statut {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 79%;
          }

          .label-statut-stade {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 145px;
          }
        }
      }

      .padding-sous-statut {
        padding: 5px 0px;
      }

      .transition-pipeline {
        width: 20px;
      }

      .menu-links {
        // padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        font-weight: 600;
        font-size: 13px;
        // height: 100%;
        background-color: #f6f6f6;

        .padding-title {
          padding: 12px 7px;
        }

        .menu-title {
          margin: 5px 0;
          font-size: 13px;
          text-transform: uppercase;
          color: #26292cad;
        }

        .menu-item {
          display: block;
          font-size: 10px;
          color: #495057;
          margin: 4px 0px;
          padding: 6px 10px;
          border-radius: 2px;
          text-decoration: none;

          &:hover {
            background-color: #49505726;
          }

          &.router-link-active {
            color: #2dabe2;
            background-color: #2dabe226;
          }
        }

        .menu-type-projet {
          background-color: #f6f6f6;

          &.v-list--nav {
            padding-left: 0px !important;
            padding-right: 0px !important;
          }

          .item-menu {
            margin-bottom: 0px !important;
            min-height: 30px !important;

            .link {
              a {
                overflow: hidden;
                font-weight: 400;
                font-size: 12px;
                line-height: 19.5px;
                white-space: nowrap;
                text-decoration: none;
                color: #000000;

                &.router-link-exact-active {
                  &.router-link-active {
                    color: #000000;
                    font-weight: 400;
                  }
                }
              }
            }

            .v-list-item__icon {
              margin-right: 20px !important;
              height: 26px !important;
              margin-top: 3px !important;
              margin-bottom: 4px !important;
            }

            .v-list-item__content {
              padding: 3px 0 !important;
            }

            .color-icon-current {
              border-radius: 5px;
              background-color: #f0edfa;
              // width: 100%;
              padding: 4px 7px 4px 7px;
              border: 1px solid;
              // color: #5C2DD3;
              // margin: 0px;
              font-size: 10px;
              white-space: nowrap;
              cursor: pointer;
              margin-top: -4px;
              background-color: #3a167f;
              color: #fff !important;
            }

            &.v-list-item--active {
              .text {
                font-weight: 700;
                font-size: 11px;
                color: #5c2dd3 !important;
              }

              .link {
                a {
                  overflow: hidden;
                  font-weight: 700;
                  font-size: 11px;
                  line-height: 19.5px;
                  white-space: nowrap;
                  text-decoration: none;
                  color: #5c2dd3 !important;

                  &.router-link-exact-active {
                    &.router-link-active {
                      color: #5c2dd3 !important;
                      font-weight: 700;
                    }
                  }
                }
              }
            }

            &:hover {
              background-color: rgba(112, 74, 209, 0.1) !important;
            }
          }
        }
      }
    }

    .content {
      display: block;
      // padding: 20px;
      // height: 100%;
      margin-left: 280px;
      overflow: hidden;
      // border: 1px solid #e5e5e5;
      background-color: #fff;
      // border-radius: 3px;
      box-sizing: border-box;
      max-height: calc(100vh - 119px) !important;
      height: calc(100vh - 119px) !important;
      transition: 0.5s all ease;
      transition-timing-function: ease-out;

      .padding-name-type-projet {
        padding: 10px 8px;
      }

      &.menu-hidden {
        // margin-left: 94px;
        margin-left: 23px;
        transform: translateX(0px);
        transition: 0.5s all ease;
        transition-timing-function: ease-out;
      }

      .header-add-projet {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        // margin: 8px 0px;
        .projects-loader-show-statut {
          display: block;
          height: 28px;
          width: 45%;
        }

        .title-type-statut {
          padding: 9px 0px 8px 7px;

          .title-statut {
            color: #3a167f;
            font-weight: 700;
            font-size: 16px !important;
            line-height: 24px;
          }

          .type-projet {
            font-family: 'Roboto', sans-serif;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 600;
            font-size: 10px;
          }
        }

        .action-btn-projet {
          .btn-filter {
            border-radius: 4px;
            padding: 1px 0px 0px 0px;
            font-size: 12px;

            .sz-icon-filter {
              font-size: 20px !important;
            }
          }

          .btn-add-projet {
            border-radius: 4px;
            padding: 6px 16px 6px 10px;
            font-size: 12px;

            .sz-icon-plus {
              font-size: 20px !important;
            }
          }
        }
      }

      .choisi-filter {
        .custom-filters {
          display: flex;
          align-items: center;

          .filter-item-container {
            padding: 2px 2px 2px 2px;
            color: #5c2dd3;
            border-radius: 20px;
            font-size: 12px;
            background-color: rgba(112, 74, 209, 0.1) !important;

            .filter-item {
              margin: 2px 0px 2px 8px;
            }

            .deleted-filter {
              cursor: pointer;
            }
          }
        }
      }

      .body-content {
        .card-table-projet {
          box-shadow: unset !important;
          background-color: transparent !important;

          .cadre-title {
            padding: 8px 11px 0px 4px !important;

            .btn-header-projet {
              padding: 0px 8px 0px 0px;

              &:hover {
                box-shadow: unset;
                filter: none;
                background-color: rgba(112, 74, 209, 0.1) !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.page-content {
  .header-add-projet,
  .add-projet-filter {
    .action-btn-projet {
      .v-btn:not(.v-btn--round).v-size--small {
        height: 30px;
        min-width: 30px;
      }
    }
  }

  .tabs-statut-piepline {
    margin: 0px -8px 0 -8px;

    .theme--light.v-tabs > .v-tabs-bar {
      height: 32px !important;
      background-color: #f6f6f6 !important;
      color: rgba(0, 0, 0, 0.6) !important;
    }
  }

  .body-content {
    .card-table-projet {
      .cadre-title {
        .v-input__slot {
          min-height: 25px !important;
        }

        .v-input__icon {
          height: 20px !important;
        }

        .v-icon.v-icon {
          font-size: 20px !important;
        }
      }
    }

    .table-projet {
      table {
        border-collapse: collapse;
      }

      height: 100% !important;
      max-height: 100% !important;

      .extra-border {
        position: absolute;
        top: 0;
        right: 0;
        width: 3px;
        height: 100%;
        background: #d8d8d8;
        z-index: 2;
      }

      .header-projets,
      .header-projets-2 {
        th {
          // border: 1px solid #e5e5e5;
          font-size: 11px;
          font-weight: 400;
          color: black;
          text-transform: uppercase;

          // text-transform: lowercase;
          white-space: nowrap;
          height: 25px !important;
          padding: 2px 5px;
          white-space: nowrap;
          // color: #5e5e5e;
          text-align: center;
          position: relative;
          font-size: 0.875rem;
          line-height: 1.5;
          overflow: hidden;
        }

        .v-input--selection-controls {
          margin-top: 0px !important;
          padding-top: 0px !important;
        }
      }

      .header-projets-2 {
        border: 0px;
        position: sticky;
        top: 49px;
        background-color: #fff;
        box-shadow: unset !important;
        z-index: 6;
      }

      .header-projets {
        border: 0px;
        position: sticky;
        top: -1px;
        background-color: #fff;
        box-shadow: unset !important;
        z-index: 7;

        .th-checked {
          padding: 2px 11px;

          &.sticky-check {
            position: sticky;
            z-index: 3;
            left: 0px;
            background-color: #fff;
          }
        }

        .sticky-date {
          position: sticky;
          z-index: 3;
          left: 54px;
          background-color: #fff;
        }
      }

      .title-table {
        font-size: 13px;
        font-weight: 700;
        font-family: 'Roboto', sans-serif;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
      }

      tbody {
        td,
        th {
          padding: 2px 5px;
          white-space: nowrap;
          color: #5e5e5e;
          text-align: center;
          position: relative;
          font-size: 12px;
          line-height: 1.5;
          overflow: hidden;
        }

        tr {
          &:hover {
            cursor: pointer;

            .main-td-content,
            .sub-td-content {
              text-decoration: underline;
            }
          }
        }

        .sticky-check {
          position: sticky;
          z-index: 3;
          left: 0px;
          background-color: #fff;

          &:hover {
            tr {
              &:hover {
                background-color: transparent !important;
              }

              .main-td-content,
              .sub-td-content {
                text-decoration: none !important;
              }
            }
          }
        }

        .sticky-date {
          position: sticky;
          z-index: 3;
          left: 54px;
          background-color: #fff;

          &:hover {
            background-color: #eeeeee;
            cursor: pointer;

            .main-td-content,
            .sub-td-content {
              text-decoration: underline;
            }
          }
        }

        .checked-table {
          padding: 2px 11px !important;
          width: 40px !important;
        }

        .v-input--selection-controls {
          margin-top: 0px !important;
          padding-top: 0px !important;
        }
      }

      .v-data-table__wrapper {
        max-height: calc(100vh - 347px) !important;
        height: calc(100vh - 347px) !important;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: auto;
      }

      &.table-projet-filter {
        .v-data-table__wrapper {
          max-height: calc(100vh - 390px) !important;
          height: calc(100vh - 390px) !important;
          margin-bottom: 0;
          overflow-y: auto;
          overflow-x: auto;
        }
      }

      .v-data-table__wrapper::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }

      .v-data-table__wrapper::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #f0eef8;
        border-radius: 4px;
        background-color: rgba(238, 238, 238, 1) !important;
      }

      .v-data-table__wrapper::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(189, 189, 189, 1);
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.page-content {
  table {
    th,
    td {
      &.blue {
        color: #2dabe2;
      }

      .color-tag {
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 100%;
        z-index: 1;

        &.Planifiée,
        &.Planifié,
        &.Posé,
        &.Réalisée,
        &.Signé,
        &.Envoyé,
        &.Accordé,
        &.MPR.Elig,
        &.MPR.Envoyé,
        &.MPR.Accordé,
        &.AL.Elig,
        &.AL.Envoyé,
        &.AL.Accordé,
        &.Validé,
        &.Facturé,
        &.Attente.Pièces,
        &.Ouvert,
        &.Complet {
          background: #0cb277;
        }

        &.Annulée,
        &.Annulé,
        &.Refusé,
        &.Non.signé,
        &.MPR.non.Elig,
        &.MPR.Refusé,
        &.AL.non.Elig,
        &.AL.Refusé,
        &.Non.éligible {
          background: #ff6961;
        }

        &.Reportée,
        &.Reporté,
        &.Réglé {
          background: #feca1d;
        }
      }
    }

    tr {
      &.lost {
        td {
          &.blue,
          .main-td-content {
            color: #ff6961;
            font-style: italic;
          }
        }
      }

      &.won {
        td {
          &.blue,
          .main-td-content {
            color: #feca1d;
            font-style: italic;
          }
        }
      }

      &.open {
        td {
          &.blue,
          .main-td-content {
            color: #0cb277;
            font-style: italic;
          }
        }
      }
    }
  }
}

.tootltip-pipeline {
  // background: rgba(112, 74, 209, 0.1) !important;
  background-color: #fff !important;
  color: #5c2dd3 !important;
  border: 1px solid;
  border-radius: 4px;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  padding: 2px 11px;
  position: absolute;
  text-transform: initial;
  width: auto;
  opacity: 0;
  pointer-events: none;

  &.hidden {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #5c2dd3;
    left: -8px;
    top: 4px;
  }

  &:after {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fcfcfc;
    left: -8px;
    top: 4px;
  }
}

.tootltip-pipeline.menuable__content__active {
  &.hidden {
    opacity: 0;
  }

  opacity: 1;
}

.list-item-pipeline-global-scroll {
  padding: 16px 16px;

  &.padding-etape {
    padding: 7px 7px;
  }
  .block-right-step {
    width: 19%;
    position: absolute;
    right: 47px;
    text-align: right;
    font-size: 10px;
    .count-step {
      text-align: center;
      height: 24px;
      padding: 6px 6px 6px 5px;
      min-width: 67% !important;
      background-color: #5c2dd3 !important;
      max-width: 100%;
      display: inline-block;
      color: #fff !important;
      font-size: 11px !important;
      border-radius: 4px !important;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .padding-statut {
    padding: 7px 7px 7px 7px;
    border-radius: 6px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    &.border-none {
      border: 1px solid #5c2dd3;
    }
    .border-step {
      border-bottom: 1px solid #5c2dd3;
      height: 40px;
      padding-bottom: 9px;
    }
  }
  .padding-category {
    padding: 7px 7px 7px 7px;
    margin-bottom: 10px;
    &.hover-none {
      &:hover {
        background-color: #e5e5e5 !important;
        border-radius: 4px !important;
        cursor: pointer;
      }
    }
    .border-step-category {
      border-bottom: 1px solid #000000;
      padding-bottom: 9px;
    }
    &.active {
      // border-radius: 4px !important;
      cursor: pointer;
      // border: 1px solid;
      // color: #5c2dd3;
    }
  }

  height: max-content;
  overflow: auto;
  max-height: 60vh;

  .list-item-etape {
    margin-bottom: 4px;

    &.active,
    &:focus,
    &:hover {
      background-color: #f6f6f6 !important;
      border-radius: 3px !important;
    }

    .title-etape {
      max-width: 310px;
      flex: 1 1 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .block-right {
      width: 11%;
      margin-left: 0px;
      text-align: left;
      font-size: 10px;

      .count {
        text-align: center;
        height: 24px;
        padding: 5px 6px 6px 5px;
        background-color: #3a167f !important;
        min-width: 50%;
        max-width: 100%;
        display: inline-block;
        color: #fff !important;
        font-size: 11px !important;
        border-radius: 4px !important;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .list-item {
    min-height: 35px !important;

    &.active,
    &:focus,
    &:hover {
      background-color: #e5e5e5 !important;
      border-radius: 6px !important;
    }
  }
}

.list-item-pipeline-global-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.list-item-pipeline-global-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 1) !important;
}

.list-item-pipeline-global-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: black;
  cursor: pointer !important;
}

.overlay-app {
  z-index: 15 !important;
}
.overlay-app-Gestion-appel {
  z-index: 15 !important;
}
</style>
